import '../stylesheets/main.scss';

// 電話バナー
function setFadeElement() {
	var windowH = $(window).height();
	var scroll = $(window).scrollTop();

	var contentsTop = Math.round(
		$('#area').offset().top
	);
	var contentsH = $('#area').outerHeight(true);

	if (
		scroll + windowH >= contentsTop &&
		scroll + windowH <= contentsTop + contentsH
	) {
		$('#side-contact').addClass('UpMove');
		$('#side-contact').removeClass('DownMove');
		$('.hide-btn').removeClass('hide-btn');
	} else {
		if (!$('.hide-btn').length) {
			$('#side-contact').addClass('DownMove');
			$('#side-contact').removeClass('UpMove');
		}
	}
}

$(window).scroll(function () {
	setFadeElement();
});

// ページトップスクロール
$('#page-top').click(function () {
	$('body,html').animate(
		{
			scrollTop: 0,
		},
		500
	);
	return false;
});

// ローディング
$(window).on('load', function () {
	$('#splash').delay(500).fadeOut('slow');
	$('#splash_logo').delay(400).fadeOut('show');
});
